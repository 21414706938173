import { IconType, defaultProps } from './types';

/**
 * Icono ArrowRight
 */
export const ArrowRight = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.448 2 24 11.552l-9.552 9.553-1.154-1.154 7.582-7.583H0v-1.632h20.876l-7.582-7.582L14.448 2Z"
      fill={color}
    />
  </svg>
);
