import { Element, HTMLReactParserOptions, domToReact } from "html-react-parser";
import parse from "html-react-parser";
import Image from "next/image";
import Link from "next/link";
import { isRelative } from "lib/utils/utils";

/*TODO */
const options: HTMLReactParserOptions = {
    replace: (domNode) => {
        if (domNode instanceof Element) {
            if (domNode.name === "img") {
                const {
                    src,
                    alt,
                    class: className,
                    width,
                    height,
                } = domNode.attribs;

                if (isRelative(src)) {
                    if (!width || !height) {
                        return (
                            <img src={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${src}`} className={className} />
                        )
                    } else {
                        return (
                            <Image
                                src={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${src}`}
                                width={parseInt(width)}
                                height={parseInt(height)}
                                alt={alt}
                                className={className}
                            />
                        );
                    }
                }
            }

            if (domNode.name === "a") {
                const { href, class: className } = domNode.attribs;

                if (href && isRelative(href)) {
                    return (
                        <Link className={className} href={href}>
                            {domToReact(domNode.children)}
                        </Link>
                    );
                }
            }
        }
    },
};


interface FormattedTextProps {
    html?: {
        processed: string
    } | string
}

export function ParseText({ html }: FormattedTextProps) {
    if (!html || html == undefined) return null;
    return <>{typeof html == 'string' ? parse(html) : parse(html.processed, options)}</>;
}

