import { IconType, defaultProps } from './types';

/**
 * Icono Bag
 */
export const Bag = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M16.125 7.672v2.062a.687.687 0 0 1-.688.688h-.687v-2.75h-5.5v2.75h-.688a.688.688 0 0 1-.687-.688V7.672h-2.75v12.375h13.75V7.672h-2.75Zm-8.25-1.375a4.125 4.125 0 0 1 8.25 0h3.438a.687.687 0 0 1 .687.687v13.75a.687.687 0 0 1-.688.688H4.438a.688.688 0 0 1-.687-.688V6.984a.688.688 0 0 1 .688-.687h3.437Zm1.375 0h5.5a2.75 2.75 0 1 0-5.5 0Z"
      fill={color}
    />
  </svg>
);
