import { IconType, defaultProps } from './types';

/**
 * Icono Loading
 */
export const Loading = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <style>{`
      .loading {
        transform-box: fill-box;
        transform-origin: center;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    `}</style>

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 11.5A7.5 7.5 0 1 1 12.5 4"
      stroke={color}
      className="loading"
    />
  </svg>
);
