import { FunctionComponent } from 'react';

import styles from './Prose.module.css';

import { TypeProseProps } from './types';
import { ParseText } from '@/utils/parseText';

/**
 * El componente prose da estilos al texto rico que
 * se recibe desde un CMS.
 */
export const Prose: FunctionComponent<TypeProseProps> = ({
  as: HTMLTag = 'div',
  html,
  className,
}: TypeProseProps) => (
  <HTMLTag className={`${className} prose text-inherit max-w-full ${styles['c-prose']}`}><ParseText html={html} /></HTMLTag>
);

Prose.displayName = 'Prose';
