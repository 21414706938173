import { ComponentPropsWithRef, ElementType, Ref, forwardRef } from 'react';
import { oneLine } from 'common-tags';

import { Loading } from '@/icons';

import '@/lib/utils/forwardRef';

import { ButtonProps, ButtonVariant } from './types';

const VARIANT_MAPS: Record<ButtonVariant, string> = {
  [ButtonVariant.DISPLAY]:
    'bg-primary-500 h-8 py-[10px] px-6 min-h-[56px] w-full md:min-w-[140px] md:w-auto text-white hover:bg-primary-600',
  [ButtonVariant.PRIMARY]:
    'bg-primary-500 py-[10px] px-6 min-h-[48px] w-full md:min-w-[140px] md:w-auto text-white hover:bg-primary-600',
  [ButtonVariant.ICON]:
    'bg-primary-500 rounded-full p-4 hover:bg-primary-600 text-white',
  [ButtonVariant.TEXTINPUT]:
    'min-h-[56px] min-w-[56px] bg-primary-500 text-white hover:bg-primary-600',
};
const OUTLINE =
  'bg-transparent text-primary-400 border border-solid border-primary-500 text-button text-primary-500 hover:bg-transparent hover:text-primary-600 hover:border-primary-600';

/**
 * Botón
 */
const ButtonBase = <T extends ElementType = 'button'>(
  {
    className = '',
    variant,
    as,
    children,
    outline = false,
    loading = false,
    ...props
  }: ButtonProps<T> & Omit<ComponentPropsWithRef<T>, keyof ButtonProps<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: Ref<any>
) => {
  const HTMLTag = as || 'button';
  return (
    <HTMLTag
      className={oneLine`
        cursor-pointer text-button flex justify-center items-center gap-x-[10px] text-center
        disabled:opacity-30 disabled:pointer-events-none aria-disabled:opacity-30 aria-disabled:pointer-events-none
        ${VARIANT_MAPS[variant]}
        ${outline ? OUTLINE : ''}
        ${className ?? ''}
      `}
      ref={ref}
      {...props}
    >
      {loading ? <Loading width={30} /> : children}
    </HTMLTag>
  );
};

export const Button = forwardRef(ButtonBase);
